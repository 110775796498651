import React from 'react';

interface IPageContext {
	page: string;
	data: any;
	downloadLink: string;
	apkPromise: any;
}

const PageContext = React.createContext<IPageContext>({} as IPageContext);

export default PageContext;
