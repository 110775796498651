import { graphql, useStaticQuery } from "gatsby"

import { FooterDataProps, ImageWithUrl, TextWithUrl } from "../../../types/types"


const processFooterData = (data: any): FooterDataProps => {
  const myData = data.allWpPage.edges[0].node.footerFields
  const socialiconArray = myData.socialmediaiconarray
  const socialMediaIconsData = []
  socialiconArray.map((iconData, index) => {
    const image = { imageFile: iconData?.images?.image?.imagefile?.localFile?.childImageSharp?.gatsbyImageData, altText: iconData?.images?.image?.alttext }
    const IconObject: ImageWithUrl = {
      image: image,
      url: iconData.images.url,

    }
    socialMediaIconsData.push(IconObject)
  })
  const paymentIconsArray = []
  const paymentArray = myData.paymentsiconarray
  paymentArray.map((imageObj, index) => {
    const image = { imageFile: imageObj?.image?.imagefile?.localFile?.childImageSharp?.gatsbyImageData, altText: imageObj?.image?.alttext }

    paymentIconsArray.push(image)

  })
  const pageArray = []
  const menu = myData?.pagesarray
  menu.map((pageLinkData, index) => {

    const menuItemObject: TextWithUrl = {
      text: { text: pageLinkData?.textwithurl?.text, color: pageLinkData?.textWithUrl?.text?.color ?? "#FFF" },
      url: pageLinkData?.textwithurl.url
    }

    pageArray.push(menuItemObject);

  })

  const blogArray = []
  const blogMenu = myData?.blogsarray
  blogMenu.map((pageLinkBlogData, index) => {

    const blogMenuItemObject: TextWithUrl = {
      text: { text: pageLinkBlogData?.textwithurl?.text, color: pageLinkBlogData?.textWithUrl?.text?.color ?? "#FFF" },
      url: pageLinkBlogData?.textwithurl.url
    }

    blogArray.push(blogMenuItemObject);

  })

  const footerData: FooterDataProps = {
    rightTitle: { text: myData.righttitle.title, color: myData.righttitle.color },
    logoImage: { imageFile: myData?.logoimage?.imagefile?.localFile?.childImageSharp?.gatsbyImageData, altText: myData?.logoimage?.alttext },
    leftTitle: myData.lefttitle,
    copyRightText: myData.copyrighttext,
    aboutUsTitle: myData.aboutustitle,
    blogTitle: myData.blogtitle,
    paymentsIconArray: paymentIconsArray,
    socialMediaIconArray: socialMediaIconsData,
    pagesArray: pageArray,
    blogsArray: blogArray,
    backgroundImage: myData.background?.image?.localFile?.childImageSharp?.gatsbyImageData,
    iconBackgroundColor: myData.socialmediaiconbackgroundcolor,
    backgroundColor: myData.background.color

  }
  return footerData
}

export const useQuery = (): FooterDataProps => {
  const data = processFooterData(
    useStaticQuery(graphql`
        {
          allWpPage(filter: {uri: {eq: "/footer/"}}) {
            edges {
              node {
                id
                uri
                footerFields {
                  copyrighttext {
                    color
                    text
                  }
                  aboutustitle {
                    color
                    text
                  }
                  blogtitle {
                    color
                    text
                  }
                  righttitle {
                    color
                    title
                  }
                  lefttitle {
                    image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                  pagesarray {
                    textwithurl {
                      url
                      text
                    }
                  }
                  blogsarray {
                    textwithurl {
                      url
                      text
                    }
                  }
                  socialmediaiconbackgroundcolor
                  paymentsiconarray {
                    image {
                      alttext
                      imagefile {
                        localFile {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                    }
                  }
                  socialmediaiconarray {
                    images {
                      image {
                        alttext
                        imagefile {
                          localFile {
                            childImageSharp {
                              gatsbyImageData
                            }
                          }
                        }
                      }
                      url
                    }
                  }
                  logoimage {
                    alttext
                    imagefile {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                  background {
                  
                    image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                    color
                  }
                }
              }
            }
          }
        }
		`)
  );
  return data;
};

export default useQuery;