import { graphql, useStaticQuery } from "gatsby"

import { HeaderData, TextWithUrl } from "../../../types/types"

export const processHeaderData = (data: any): HeaderData => {

  const myData = data.allWpPage.edges[0].node.headerFields
  const tabs = myData.tabs
  const tabArray = []
  tabs.forEach((tab, index) => {
    const textWithUrl: TextWithUrl = {
      text: { text: tab.textwithurl.text, color: "#FFF" },
      url: tab.textwithurl.url
    }
    tabArray.push(textWithUrl)
  })

  const headerData: HeaderData = {
    logoImage: { imageFile: myData?.headerlogoimage?.imagefile?.localFile?.childImageSharp?.gatsbyImageData, altText: myData?.headerlogoimage?.alttext },
    textColor: myData?.menucolor,
    tabArray: tabArray,
    submenutabs: myData.submenutabs,
    closeIcon: myData?.closeicon?.localFile?.childImageSharp?.gatsbyImageData,
    hamIcon: myData?.hamburgericon?.localFile?.childImageSharp?.gatsbyImageData,
    backgroundColor: myData?.backgroundcolor,
    backgroundImage: myData?.background?.image?.localFile?.childImageSharp?.gatsbyImageData


  }
  return headerData
}


const useQuery = (): HeaderData => {
  const data = processHeaderData(
    useStaticQuery(graphql`
        {
            allWpPage(filter: {uri: {eq: "/header/"}}) {
              edges {
                node {
                  headerFields {
                    backgroundcolor
                    closeicon {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                    hamburgericon {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                    tabs {
                      textwithurl {
                        text
                        url
                      }
                    }
                    submenutabs {
                      text
                      submenulabel {
                        text
                        url
                      }
                    }
                    headerlogoimage {
                      alttext
                      imagefile {
                        localFile {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
		`)
  );
  return data;
};

export default useQuery;