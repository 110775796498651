import { OperatingSystem } from "../src/constants";
import { isEmptyOrNull } from "./textUtils";

export const linkResolver = (iosdownloadlink) => {

    if (osDetector() === OperatingSystem.IOS) {
        return iosdownloadlink
    }
    let x = Math.random() * 100;
    let controlPercentage = parseInt(process.env.GATSBY_CONTROL_ABTEST);
    if (!process.env.GATSBY_CONTROL_ABTEST || isEmptyOrNull(process.env.GATSBY_TEST_LINK)) {
        controlPercentage = 100
    }
    let hrefValue;
    if (x < controlPercentage) {
        hrefValue = process.env.GATSBY_CONTROL_LINK;
    } else {
        hrefValue = process.env.GATSBY_TEST_LINK;
    }
    console.log("control Link : ", process.env.GATSBY_CONTROL_LINK, "test Link : ", process.env.GATSBY_TEST_LINK, "controlPercentage : ", controlPercentage)
    return hrefValue;
}
export const osDetector = () => {
    var ua = navigator.userAgent.toLowerCase();

    if (ua.indexOf("android") > -1) return OperatingSystem.ANDROID
    if (ua.indexOf("iphone") > -1) return OperatingSystem.IOS
    return OperatingSystem.OTHERS
}

// apk link from APIs
export const getApkDynamiClick = () => {
    return fetch(process.env.APK_LINK_URL, {
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify({ websiteName: process.env.WEBSITE_NAME }),
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' },
        cache: 'force-cache'
    })
        .then((response) => response.json())
        .then((response) => response)
        .catch((err) => {
            {
                process.env.NODE_ENV === "development" ? (
                    console.log(err)
                ) : null
            }
        });
}

// to know the apk download from (onClick action)
export const knowTheApkOrigin=(uniqueUser, noOfSession, labelAction, timestamp, pageUrl)=>{
    return fetch(`https://logevents.zupee.com/logevents?appName=ludodownload&uuid=${uniqueUser}&sessionid=${noOfSession}&label=${labelAction}&timestamp=${timestamp}&pageUrl=${pageUrl}`, {
        mode: 'cors', 
        method: 'PUT', 
        headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }, 
        cache: 'no-cache'
    })
    .then((response)=>response.json())
    .then((response)=>response)
    .catch((err)=>console.log("Error", err));
}

// to know the session and unique user count (onLoad action)
export const knowTheApkOriginOnLoad=(uniqueUser, noOfSession, labelAction, timestamp, pageUrl)=>{
    return fetch(`https://logevents.zupee.com/logevents?appName=ludodownload&uuid=${uniqueUser}&sessionid=${noOfSession}&label=${labelAction}&timestamp=${timestamp}&pageUrl=${pageUrl}`, {
        mode: 'cors', 
        method: 'PUT', 
        headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }, 
        cache: 'no-cache'
    })
    .then((response)=>response.json())
    .then((response)=>response)
    .catch((err)=>console.log("Error", err));
}

// to know the apk download from (thankyou page action)
export const knowTheApkOriginForThankyou=(uniqueUser, noOfSession, labelAction, timestamp, redirectedFrom)=>{
    return fetch(`https://logevents.zupee.com/logevents?appName=ludodownload&uuid=${uniqueUser}&sessionid=${noOfSession}&label=${labelAction}&timestamp=${timestamp}&redirectedFrom=${redirectedFrom}`, {
        mode: 'cors', 
        method: 'PUT', 
        headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }, 
        cache: 'no-cache'
    })
    .then((response)=>response.json())
    .then((response)=>response)
    .catch((err)=>console.log("Error", err));
}