import React, { useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';

import { TextProps, WinningText } from '../../../types/types';
import './stickyDownloadButton.modules.css';
import useQuery from './useQuery';
import { ANALYTICS_CONSTANTS, OperatingSystem, THANKYOU_PAGE_PATH } from '../../../constants';
import { useState } from 'react';
import { useEffect } from 'react';
import { osDetector } from '../../../../utils/downloadLinkUtils';
import { isEmptyOrNull } from '../../../../utils/textUtils';
import AnalyticsContext from '../../../context/analytics/analyticsContext';
import { logEventsOnCLick, redirectCheck } from '../../../../utils/redirectCheck';
import PageContext from "../../../context/page/pageContext";

interface DownloadButtonProps {
	buttonText: TextProps;
	joiningBonusText: TextProps;
	downloadLink: string;
	buttonColor: string;
	image: any;
	buttonBackgroundImage: any;
	isAndroid: boolean;
	buttonColorStart: string;
	buttonColorEnd: string;
}

const DownloadButton = (props: DownloadButtonProps) => {
	const analyticsPrefix = useContext(AnalyticsContext);
	const { apkPromise } = useContext(PageContext);

	const { buttonText, downloadLink, image, isAndroid, buttonColor, buttonColorEnd, buttonColorStart } = props;
	const handleDownload = () => {
		let dataLayer = window.dataLayer || [];

		dataLayer.push({
			event: analyticsPrefix + ANALYTICS_CONSTANTS.EVENT,
			category: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.CATEGORY,
			action:
				analyticsPrefix +
				(isAndroid ? ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.ACTION : ANALYTICS_CONSTANTS.IOS_APP_STORE_EVENT.ACTION),
			label:
				analyticsPrefix +
				(isAndroid ? ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.LABEL : ANALYTICS_CONSTANTS.IOS_APP_STORE_EVENT.LABEL)
		});
		if (isAndroid) {
			redirectCheck(apkPromise);
			logEventsOnCLick();
		} else {
			window.open(downloadLink);
			logEventsOnCLick();
		}
	};
	return (
		<div className='sticky-bottom-content-container'>
			{/* <GatsbyImage image={buttonBackgroundImage.imageFile} alt={buttonBackgroundImage.altText} className='sticky-bottom-background-image-field' /> */}

			<div className='sticky-bottom-content-div'>
				<div
					// href={!isEmptyOrNull(downloadLink) ? downloadLink : process.env.GATSBY_CONTROL_LINK}
					className={`sticky-bottom-download-link ${isAndroid ? 'apk-download-link' : ''}`}
					// download={isAndroid}
					onClick={handleDownload}>
					<div
						style={{ background: `linear-gradient(to bottom,${buttonColorStart} 1%,${buttonColorEnd} 100%)` }}
						className='sticky-bottom-download-button-container'>
						{/* <div className='sticky-bottom-joining-bonus-container'>
                            <p
                                style={{ color: joiningBonusText.color }}
                                className='sticky-bottom-joining-bonus-text sticky-bottom-p-tag'>
                                {joiningBonusText?.text}
                            </p>
                        </div> */}
						{/* <div className='sticky-bottom-download-button-text-div'> */}
						<GatsbyImage image={image?.imageFile} alt={image.altText} className='sticky-bottom-os-image' />
						<p style={{ color: buttonText.color }} className='sticky-bottom-download-text sticky-bottom-p-tag'>
							{buttonText.text}
						</p>
						{/* </div> */}
					</div>
				</div>
			</div>
		</div>
	);
};

const StickyBottomBar = () => {
	const data = useQuery();
	const { downloadButtonData, winningText, joiningBonusText } = data;
	const [btnImage, setBtnImage] = useState({});
	const [downloadLink, setDownloadLink] = useState('');
	const [isAndroid, setAndroid] = useState(true);
	useEffect(() => {
		if (osDetector() === OperatingSystem.ANDROID) {
			setBtnImage(downloadButtonData?.button?.buttonAndroidImage);
			// setDownloadLink(downloadLink);
			setAndroid(true);
		} else if (osDetector() === OperatingSystem.IOS) {
			setBtnImage(downloadButtonData?.button?.buttonIosImage);
			setDownloadLink(downloadButtonData?.button?.buttonIosLink);
			setAndroid(false);
		}
	}, []);

	return (
		<div className='sticky-bottom-container'>
			<span className='sticky-bottom-joining-bonus-blink-text'>{joiningBonusText.text}</span>
			<DownloadButton
				downloadLink={downloadLink}
				buttonText={downloadButtonData?.button?.buttonText}
				image={btnImage}
				buttonColor={downloadButtonData?.button?.buttonColor}
				joiningBonusText={joiningBonusText}
				buttonBackgroundImage={downloadButtonData?.button?.buttonBackgroundImage}
				isAndroid={isAndroid}
				buttonColorStart={downloadButtonData?.button?.buttonColorStart}
				buttonColorEnd={downloadButtonData?.button?.buttonColorEnd}
			/>
			{/* <WinningTextComponent {...winningText} /> */}
		</div>
	);
};

const WinningTextComponent = (props: WinningText) => {
	return (
		<div className='sticky-bottom-section-winning-text-container'>
			{props.image && <img src={props.image.imageFile} alt={props.image.altText} className='sticky-bottom-winning-text-gif' />}
			{props.text && (
				<p style={{ color: props?.text?.color ?? '#FFF' }} className='sticky-bottom-winning-text-field sticky-bottom-p-tag'>
					{props?.text?.text}
				</p>
			)}
		</div>
	);
};

export default StickyBottomBar;
