import { navigate } from 'gatsby';
import { THANKYOU_PAGE_PATH } from "../src/constants";
import { knowTheApkOrigin, knowTheApkOriginForThankyou, knowTheApkOriginOnLoad } from './downloadLinkUtils';

// for api from APK
export function apkPromiseFunction(apkPromise, target) {
  apkPromise.then((res) => {
    if (res) {
      window.open(res.apkLink, target);
    } else {
      window.open(process.env.DEFAULT_APK_LINK, target);
    }
  })
}

// for redirect to thankyou page
export function redirectToThankyou() {
  navigate(THANKYOU_PAGE_PATH);
}

// common - redirect to thankyou page
export function redirectCheck(apkPromise) {
  apkPromiseFunction(apkPromise, "_blank");
  redirectToThankyou();
}
// for logevents- usecase for onClick 
export function logEventsOnCLick() {
  let uniqueUser = localStorage.getItem('newUUID');
  let noOfSession = sessionStorage.getItem('sessionstore');
  let labelAction = 'onClick';
  let timestamp = new Date().valueOf();
  const pageUrl = window.location.href;
  knowTheApkOrigin(uniqueUser, noOfSession, labelAction, timestamp, pageUrl);
}
// for logevents- usecase for onLoad
export function logEventsOnLoad() {
  let sessionstore = sessionStorage.getItem('sessionstore');
  if (!sessionstore) {
    sessionstore = '0';
  }
  let sessionCount = parseInt(sessionstore, 10);
  if (sessionCount < 1) {
    sessionCount = 1;
  } else {
    sessionCount += 1;
  }
  sessionStorage.setItem('sessionstore', sessionCount.toString());
  let uniqueUser = localStorage.getItem('newUUID');
  let noOfSession = sessionStorage.getItem('sessionstore');
  let labelAction = 'onLoad';
  let timestamp = new Date().valueOf();
  const pageUrl = window.location.href;
  knowTheApkOriginOnLoad(uniqueUser, noOfSession, labelAction, timestamp, pageUrl);
}
// tracking On Thankyou Page
export function trackingOnThankyouPage() {
  let uniqueUser = localStorage.getItem('newUUID');
  let noOfSession = sessionStorage.getItem('sessionstore');
  let labelAction = 'onClick';
  let timestamp = new Date().valueOf();
  let redirectedFrom = localStorage.getItem('pageSlug');
  knowTheApkOriginForThankyou(uniqueUser, noOfSession, labelAction, timestamp, redirectedFrom);
}