import React, { useState, useLayoutEffect } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import './header.modules.css';
import useQuery from './useQuery';
import MenuModal from './MenuModal';

const Header = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [windowWidth, setWindowWidth] = useState(0);
	const [isSubmenuActive, setIsSubmenuActive] = useState(false);

	const handleMenuOpen = () => {
		setMenuOpen((prevState) => {
			return !prevState;
		});
	};
	useLayoutEffect(() => {
		const updateWindowWidth = () => {
			const isBrowser = typeof window !== 'undefined';
			if (isBrowser) {
				setWindowWidth(window?.innerWidth);
			}
		};
		updateWindowWidth();
		window.addEventListener('resize', updateWindowWidth);
		return () => {
			window.removeEventListener('resize', updateWindowWidth);
		};
	}, []);

	const data = useQuery();
	const { logoImage, tabArray, hamIcon, closeIcon, backgroundColor, submenutabs } = data;
	const handleMenuClose = () => {
		setMenuOpen(false);
	};

	function handleDropdown() {
		setIsSubmenuActive(!isSubmenuActive);
	}

	return (
		<nav className='navBar' style={{ backgroundColor: backgroundColor }}>
			<div className='header-inner-container'>
				<div className='logo-image-container'>
					<Link to='/'>
						<GatsbyImage image={logoImage.imageFile} alt={logoImage.altText} />
					</Link>
				</div>
				<div className='tabList-container'>
					<p className='submenu-item' onClick={handleDropdown}>
						{submenutabs.text}
						<i className={`arrow down ${isSubmenuActive ? 'menu-active' : ''}`}></i>
						<div className={`submenu-items ${isSubmenuActive ? 'active' : ''}`}>
							{submenutabs.submenulabel.map((items, index) => {
								return (
									<a href={items.url} className={'tabText submenu-tabText'} style={{ color: '#FFF' }} key={index}>
										<p>{items.text}</p>
									</a>
								);
							})}
						</div>
					</p>

					{tabArray.map((tab, index) => {
						return (
							<>
								<a href={tab.url} className={'tabText'} style={{ color: tab.text.color ?? '#FFF' }} key={index}>
									<p>{tab.text.text}</p>
								</a>
							</>
						);
					})}
				</div>
				<div className='hamburger-btn-container'>
					<div className='hamburger-icon-div'>
						<div
							className='hamburger-icon-container'
							onClick={() => {
								handleMenuOpen();
							}}>
							<GatsbyImage image={hamIcon} alt='' className='hamburger-icon-image' />
						</div>
						<MenuModal
							open={menuOpen}
							onClose={() => handleMenuClose()}
							tabArray={tabArray}
							closeIcon={closeIcon}
							windowWidth={windowWidth}
							backgroundColor={backgroundColor}
							submenutabs={submenutabs}
						/>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Header;
