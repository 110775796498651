import React from 'react';

import { TextProps, TextWithUrl } from '../../../types/types';
import './footer.modules.css';
interface FooterMenuArray {
	pagesArray: Array<TextWithUrl>;
	blogsArray: Array<TextWithUrl>;
	aboutUsTitle: TextProps;
	blogTitle: TextProps;
}
const FooterMenu = (props: FooterMenuArray) => {
	const pagesArray = props.pagesArray;
	const blogsArray = props.blogsArray;
	const aboutUsTitle = props.aboutUsTitle;
	const blogTitle = props.blogTitle;
	return (
		<React.Fragment>
			<p style={{ margin: '0px 0px 10px' }}>{aboutUsTitle.text}</p>
			{pagesArray.map((page, index) => {
				return (
					<>
						<p style={{ color: page.text.color ?? '#FFF' }} key={index} className='footer-link-p-tag'>
							{' '}
							<a style={{ color: page.text.color ?? '#FFF' }} href={page.url} className='footer-link'>
								{page.text.text}
							</a>{' '}
							{index !== pagesArray.length - 1 && '|'}{' '}
						</p>
					</>
				);
			})}
			<p className='footer-blog-heading'>{blogTitle.text}</p>
			{blogsArray.map((page, index) => {
				return (
					<>
						<p style={{ color: page.text.color ?? '#FFF' }} key={index} className='footer-link-p-tag'>
							{' '}
							<a style={{ color: page.text.color ?? '#FFF' }} href={page.url} className='footer-link'>
								{page.text.text}
							</a>{' '}
							{index !== blogsArray.length - 1 && '|'}{' '}
						</p>
					</>
				);
			})}
		</React.Fragment>
	);
};

export default FooterMenu;
